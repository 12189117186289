import React, { useEffect } from 'react'
import { priceWithTax } from '../../../Utils/helper'
import { DropdownWithSearch } from '../../../SelectDropDown/Dropdown'
import { DropdownInput } from '../../../Inputs'
import { dropDownListObject } from '../../../Utils/contructObject'
import { DELETE } from '../../../icons'
import { useGetPriceTemplateMasterQuery } from '../../../redux/ErpServices/PriceTemplateMasterServices';
import { minValue, stepValue } from '../../../Constants'

const ProcessCostingItem = ({ index, handleInputChange, readOnly, article, uom, row, isArticleShow, isRawMaterial, isUom,
    consumptionQty, deleteRow, process, id, userDocDate, name, fabricId
}) => {
    const { data: matchingData } = useGetPriceTemplateMasterQuery({ params: { articleProcessPriceTemplateMatching: true, articleId: fabricId ? fabricId : row.articleId, processId: row.processId, userDocDate } }, { skip: (!(row.articleId || fabricId) || !row.processId || !userDocDate) })
    useEffect(() => {
        if (id || (!row?.refetch)) return
        if (!matchingData) return
        if (!matchingData?.data?.rate) return
        if (!(name === "Yarn")) {
            if (!matchingData?.data?.loss) return
            handleInputChange(index, 'loss', matchingData?.data?.loss)
        }
        handleInputChange(index, 'rate', matchingData?.data?.rate)
        if (row?.refetch) {
            handleInputChange(index, 'refetch', false)
        }
    }, [matchingData, readOnly, id, handleInputChange, index, row, name])

    return (
        <tr key={index} className='h-[30px]'>
            <td className="border border-gray-400 text-[11px] ">
                <DropdownWithSearch
                    value={row.processId}
                    setValue={(val) => handleInputChange(index, 'processId', val)}
                    options={process}
                    required
                    readOnly={readOnly} />
            </td>
            {isArticleShow &&
                <td className="border border-gray-400 text-[11px] ">
                    <DropdownWithSearch
                        value={row.articleId}
                        setValue={(val) => handleInputChange(index, 'articleId', val)}
                        options={article}
                        readOnly={readOnly}
                        required

                    />
                </td>
            }
            {isUom &&
                <td className="border border-gray-400 text-[11px] ">
                    <DropdownInput
                        value={row.uomId}
                        setValue={(val) => handleInputChange(index, 'uomId', val)}
                        options={dropDownListObject(uom, "name", "id")}
                        readOnly={readOnly}
                        required

                    />
                </td>
            }
            <td className="border border-gray-400 text-[11px] ">
                <input
                    type="number"
                    min={minValue}
                    step={stepValue}
                    required={!isRawMaterial}
                    onFocus={(e) => { e.target.select() }}
                    className='text-right w-full'
                    value={isRawMaterial ? consumptionQty : parseFloat(row.consumptionQty)}
                    disabled={isRawMaterial || readOnly}
                    onChange={(e) => handleInputChange(index, 'consumptionQty', e.target.value)}
                />
            </td>
            <td className="border border-gray-400 text-[11px] h-full">
                <input
                    type="text"
                    onFocus={(e) => { e.target.select() }}
                    disabled={readOnly}
                    value={row.loss}
                    className='text-right w-full '
                    onChange={(e) => handleInputChange(index, 'loss', e.target.value)}
                />
            </td>
            <td className="border border-gray-400 text-[11px] h-full">
                <input
                    type="number"
                    min={minValue}
                    step={stepValue}
                    required
                    onFocus={(e) => { e.target.select() }}
                    disabled={readOnly}
                    value={row.rate}
                    className='text-right w-full'
                    onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                />
            </td>
            <td className="border border-gray-400 text-[11px] ">
                <input
                    type="text"
                    onFocus={(e) => { e.target.select() }}
                    className='text-right w-full'
                    value={(parseFloat(isRawMaterial ? consumptionQty : row.consumptionQty || 0) * parseFloat(row.rate || 0)).toFixed(3)}
                    disabled
                />
            </td>
            <td className="border border-gray-400 text-[11px] ">
                <input
                    type="text"
                    onFocus={(e) => { e.target.select() }}
                    className='text-right w-full'
                    value={((parseFloat(isRawMaterial ? consumptionQty : row.consumptionQty || 0) * parseFloat(row.rate || 0) / 100) * parseFloat(row.loss || 0)).toFixed(3)}
                    disabled
                />
            </td>
            <td className="border border-gray-400 text-[11px] ">
                <input
                    type="text"
                    onFocus={(e) => { e.target.select() }}
                    className='text-right w-full'
                    value={priceWithTax(parseFloat(isRawMaterial ? consumptionQty : row.consumptionQty || 0) * parseFloat(row.rate || 0), parseFloat(row.loss || 0)).toFixed(3)}
                    disabled
                />
            </td>
            {/* <td className="border border-gray-400 text-[11px] ">
                <input
                  type="text"
                onFocus={(e) => { e.target.select() }}

                  className='text-right h-full w-full'
                  value={(priceWithTax(parseFloat(isRawMaterial ? consumptionQty : row.consumptionQty || 0) * parseFloat(row.rate || 0), parseFloat(row.loss || 0)) * parseFloat(conversionValue || 0)).toFixed(3)}
                  disabled
                />
              </td> */}
            {!readOnly &&
                <td className=' border border-gray-400'>
                    <div className='flex items-center justify-center w-full'>
                        <button
                            type='button'
                            className='text-red-500'
                            onClick={() => deleteRow(index)}
                        >
                            {DELETE}
                        </button>
                    </div>
                </td>
            }
        </tr>
    )
}

export default ProcessCostingItem
