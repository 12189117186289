import React, { useEffect, useMemo } from 'react'
import { DELETE, TICK_ICON } from '../../../icons';
import { useGetStyleFabricTemplateByIdQuery } from '../../../redux/ErpServices/StyleFabricTemplateServices';
import { DropdownWithSearch } from '../../../Inputs';

const StyleItem = ({ valueIndex, readonly, handleChange, styleData, styleFabricData, value, trimFabricDet, templateFields, handleChangeOnSubGrid,
    getConsumption, setCurrentSelectedStyleId, getSubGridValue, currentSelectedStyleId, deleteRow, setStyleItemDetails
}) => {
    const { data: styleTemplateData } = useGetStyleFabricTemplateByIdQuery(value.fabricId, { skip: (!value.fabricId) })

    const matchedTemplate = useMemo(() => styleTemplateData?.data, [styleTemplateData]);


    useEffect(() => {
        const changeTemplateDetails = () => {
            if (readonly) return
            if (!matchedTemplate) return
            setStyleItemDetails(prev => {
                if (!prev?.[valueIndex]?.['isTemplateUpdate']) return prev;
                let newItems = structuredClone(prev);
                newItems[valueIndex]['fabricCostingDetails'] = (matchedTemplate?.fabricCostingDetails || []).map(i => ({ ...i, refetch: true }));
                const totalFabricLoss = newItems[valueIndex]['fabricCostingDetails'].reduce((a, c) => (a + parseFloat(c?.loss || 0)), 0);
                newItems[valueIndex]['yarnCostingDetails'] = (matchedTemplate?.yarnCostingDetails || []).map(i => ({ ...i, loss: totalFabricLoss, refetch: true }));
                newItems[valueIndex]['isTemplateUpdate'] = false;
                return newItems
            })
        }
        changeTemplateDetails();
    }, [readonly, matchedTemplate, setStyleItemDetails, valueIndex])


    return (
        <tr key={valueIndex} className="border-b border-gray-400 text-[11px] h-[30px] ">
            <td className="  text-[11px]  justify-center border-x border-gray-400">
                <select
                    disabled={readonly}
                    name="name" className='w-full h-full md:col-span-2 col-span-1 rounded text-sm'
                    value={value.styleId}
                    required
                    onChange={(e) => { handleChange(valueIndex, "styleId", e.target.value); }} >
                    <option value="" disabled>Select</option>
                    {(styleData?.data ? styleData?.data : []).map((option, index) =>
                        <option key={index} value={option.id} >
                            {option.name}
                        </option>)}
                </select>
            </td>
            <td className=" text-[11px] flex items-center justify-center">
                <DropdownWithSearch value={value?.fabricId}
                    readOnly={readonly}
                    setValue={(value) => { handleChange(valueIndex, "fabricId", value); }}
                    options={(styleFabricData?.data ? styleFabricData?.data : [])} className='min-w-[250px]' />
            </td>
            <td className="text-[11px] border-x border-gray-400">
                <div className="flex items-center justify-center">
                    <input
                        type="checkbox"
                        checked={value?.isTrimFabric || false}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleChange(valueIndex, "isTrimFabric", isChecked);
                            if (!isChecked) {
                                handleChange(valueIndex, "trimFabricId", "");
                            }
                        }}
                        disabled={readonly}
                    />
                </div>
            </td>
            <td className="text-[11px] border-x border-gray-400 min-w-[150px]">
                <DropdownWithSearch
                    value={value?.trimFabricId}
                    readOnly={!value?.isTrimFabric || readonly}
                    setValue={(newValue) => handleChange(valueIndex, "trimFabricId", newValue)}
                    options={trimFabricDet?.data || []}
                    className="min-w-[150px]"
                />
            </td>


            {templateFields.map((field, fieldIndex) => (
                <td key={field.id} className='border-x border-gray-400 text-right'>
                    <input type='text'
                        onFocus={(e) => { e.target.select() }}
                        min={0} className='w-full text-right text-sm ' value={getSubGridValue(valueIndex, fieldIndex)}
                        onChange={(e) => { handleChangeOnSubGrid(valueIndex, fieldIndex, e.target.value) }}
                        disabled={readonly || field.formula}
                    />
                </td>
            ))}
            <td className='border-x border-gray-400 text-right'>
                {getConsumption(valueIndex)}
            </td>
            <td className='border-x border-gray-400'>
                <input value={value?.manualConsumption} type='text' className='w-full text-right'
                    disabled={readonly}
                    onChange={(e) => { handleChange(valueIndex, "manualConsumption", e.target.value) }} />
            </td>
            <td className='border-x border-gray-400 text-right'>
                {value?.consumptionQty}
            </td>
            <td className="">
                <div className='flex justify-center items-center text-[11px] text-green-600 w-full border-black h-full'>
                    <button type='button' className='w-full bg-gray-200 min-h-[30px] rounded-md'
                        onClick={() => {
                            setCurrentSelectedStyleId(valueIndex)
                        }}
                    >
                        {(parseInt(valueIndex) === parseInt(currentSelectedStyleId)) ? TICK_ICON : <>&nbsp;</>}
                    </button>
                </div>
            </td>
            {!readonly &&
                <td className="border border-gray-400 text-[11px] text-center">
                    <button
                        type='button'
                        onClick={() => {
                            deleteRow(valueIndex)
                        }}
                        className='text-[11px] text-red-600 '>{DELETE}
                    </button>
                </td>
            }
        </tr>
    )
}

export default StyleItem
