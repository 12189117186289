import React from 'react';
import { findFromList, getCommonParams, priceWithTax } from '../../../Utils/helper';
import { useGetStyleMasterQuery } from '../../../redux/ErpServices/StyleMasterServices';
import TargetComparision from './TargetComparision';

const OverallCostingSummary = ({
  styleItemDetails, conversionValue, commercialCostingDetails, targetRate,
  garmentLossPercent, setGarmentLossPercent,
  transportCostPercent, setTransportCostPercent, readOnly, setTargetRate

}) => {
  const { token, ...params } = getCommonParams();
  const { data: styleData } = useGetStyleMasterQuery({ params });
  const styleList = styleData?.data || [];
  function getStyleWiseTotal(styleId) {
    const currentStyleItems = styleItemDetails.filter(i => parseInt(i.styleId) === parseInt(styleId));
    const summary = [
      {
        name: "Yarn",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.yarnCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      }, {
        name: "Fabric",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.fabricCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      },
      {
        name: "Trims",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.accessoryCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      }, {
        name: "CMT",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.cmtCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      }
    ]
    return summary.reduce((a, c) => a + parseFloat(c.value), 0)
  }
  let distinctStyleItems = [...new Set(styleItemDetails.map(i => parseInt(i.styleId)))];
  let overAllSummary = distinctStyleItems.map(styleId => ({
    name: findFromList(styleId, styleList, "name"),
    value: getStyleWiseTotal(styleId).toFixed(3)
  }))
  function getAmount(type, rate) {

    if (type === "Percentage") return ((parseFloat(targetRate || 0) / conversionValue) / 100) * parseFloat(rate)
    if (type === "Flat") return parseFloat(rate)
    return 0
  }
  const totalAmount = overAllSummary.reduce((a, c) => a + parseFloat(c.value), 0)
  overAllSummary = [...overAllSummary, {
    name: "Commercial",
    value: commercialCostingDetails.reduce((a, c) => a + (getAmount(c.type, c.rate)), 0).toFixed(3)
  }]
  const total = overAllSummary.reduce((a, c) => a + parseFloat(c.value), 0)

  const garmentCost = ((garmentLossPercent || 0) * totalAmount / 100);
  const transportCost = parseFloat(transportCostPercent || 0);
  const overAllTotal = total + garmentCost + transportCost;
  return (
    <fieldset className='w-full text-xs grid grid-cols-2 gap-3 frame rounded-tr-lg rounded-bl-lg border border-gray-600 my-2'>
      <legend className='sub-Heading'>Overall</legend>
      <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 p-1'>
        {/* <legend className='sub-heading'>Overall Summary</legend> */}
        <div className='bg-[#388087] text-white text-center p-1'>Overall Summary</div>
        <table className='w-full text-[11px]'>
          <thead>
            <tr className='h-[40px] bg-gray-300'>
              <th className="border border-gray-400 text-[11px] w-20">
                Style
              </th>
              <th className="border border-gray-400 text-[11px] w-10">
                Amount
              </th>
              <th className="border border-gray-400 text-[11px] w-10">
                Conv. Value
              </th>
            </tr>
          </thead>
          <tbody >
            {overAllSummary.map((row, index) => (
              <tr key={index} className='h-[30px]'>
                <td className="border border-gray-400 text-[11px] pl-1">
                  {row.name}
                </td>
                <td className="border border-gray-400 text-[11px] text-right">
                  {(parseFloat(row.value)).toFixed(3)}
                </td>
                <td className="border border-gray-400 text-[11px] h-full text-right">
                  {(parseFloat(row.value) * conversionValue).toFixed(3)}
                </td>
              </tr>
            ))}
            <tr className='h-[30px] border-green-600 border-2'>
              <td className="border border-gray-400 text-[11px] pl-1">
                <div className='grid grid-cols-2 justify-center items-center'>
                  <span>
                    Garment Loss %
                  </span>
                  <input type="text" className='text-right focus:outline-none border border-gray-400 ' value={garmentLossPercent} onChange={(e) => { setGarmentLossPercent(e.target.value) }} disabled={readOnly} />
                </div>
              </td>
              <td className="border border-gray-400 text-[11px] h-full text-right">
                {(garmentCost).toFixed(3)}
              </td>
              <td className="border border-gray-400 text-[11px] h-full text-right">
                {(garmentCost * conversionValue).toFixed(3)}
              </td>
            </tr>
            <tr className='h-[30px] border-green-600 border-2'>
              <td className="border border-gray-400 text-[11px] pl-1">
                Transport Cost
              </td>
              <td className="border border-gray-400 text-[11px] h-full text-right">
                <input type="text" className='text-right focus:outline-none' value={transportCostPercent} onChange={(e) => { setTransportCostPercent(e.target.value) }} disabled={readOnly} />
              </td>
              <td className="border border-gray-400 text-[11px] h-full text-right">
                {(transportCost * conversionValue).toFixed(3)}
              </td>
            </tr>
            <tr className='h-[30px] border-green-600 border-2'>
              <td className="border border-gray-400 text-[11px] font-bold pl-1">
                Costing
              </td>
              <td className="border border-gray-400 text-[11px] h-full text-right">
                {(overAllTotal).toFixed(3)}
              </td>
              <td className="border border-gray-400 text-[11px] h-full text-right">
                {(overAllTotal * conversionValue).toFixed(3)}
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <TargetComparision readOnly={readOnly} setTargetRate={setTargetRate} conversionValue={conversionValue} targetRate={targetRate} overAllTotal={overAllTotal} />
    </fieldset>
  );
};

export default OverallCostingSummary;
